import React from "react"
import { useState, useEffect } from "react"
import axios from "axios"

function createPlaylist(token, trackUri, code, setPlaylistStatus){

  axios
    .post("/playlist", {
      params: {
        name: "test playlist hello",
        public: true,
        code: code,
        accessToken: token,
        trackUri: trackUri,
      },
    })
    .then(res => {
      //set status of playlist (if it was made or if there were errors)
      setPlaylistStatus(res);
    })
}
//playlist stuff end

export default function PlaylistMaker({ accessToken, trackUri, code, setPlaylistStatus}) {

  if (!accessToken) return null
  return (
    <button className="btn btn-success" onClick={() => createPlaylist(accessToken, trackUri, code, setPlaylistStatus)}>Create Playlist</button>
  )
}
