import React from "react"

export default function TrackSearchResult({ track, chooseTrack }) {
  function handlePlay() {
    chooseTrack(track)
  }

  let songLink = 'https://open.spotify.com/track/' + track.uri

  return (
    <div
      className="d-flex m-2 align-items-center"
      style={{ cursor: "pointer", backgroundColor: "#DDDDDD" , borderRadius: "5px", marginBottom: "2px"}}
      onClick={handlePlay}
    >
      <img className="m-2" src={track.albumUrl} style={{ height: "64px", width: "64px" }} />
      <div className="ml-3">
        <div>{track.title}</div>
        <div className="text-muted">{track.artist}</div>
        <div><a target="_blank" href={songLink}>Listen on Spotify</a></div>
      </div>
    </div>
  )
}
