import React from "react"
import { useState, useEffect } from "react"
import axios from "axios"
import { Jumbotron, Row, Col } from "react-bootstrap";


export default function SelectedTrack({ track }) {

    if (!track) return <></>;
    return (
        <Jumbotron>
            <h3>Selected Track: </h3>
            <Row style={{flex: 'row'}}>
                
                    <img src={track.albumUrl} style={{ height: "64px", width: "64px" }} />
                
                
                    <div className="ml-3">
                        <div>{track.title}</div>
                        <div className="text-muted">{track.artist}</div>
                    </div>
                
            </Row>
        </Jumbotron>
    )
}
