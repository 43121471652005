import React from "react"
import { Container, Jumbotron } from "react-bootstrap"
import axios from "axios"
var querystring = require('querystring');

let client_id = 'b8b92e09fc804769ad39a8c8200612c5'; // Your client id
//let redirect_uri = 'https://bcon-cloud.uc.r.appspot.com'; // Your redirect uri
let redirect_uri = 'https://spotify.swiftie.dev'; // Your redirect uri
//let redirect_uri = 'http://localhost:3000'; // Your redirect uri

var scope = 'streaming user-read-playback-state user-modify-playback-state user-read-email user-read-private playlist-modify-public'
//playlist-modify-private user-library-read user-library-modify

let AUTH_URL = 'https://accounts.spotify.com/authorize?' +
  querystring.stringify({
    response_type: 'code',
    client_id: client_id,
    scope: scope,
    redirect_uri: redirect_uri
})

//const AUTH_URL =
//"https://accounts.spotify.com/authorize?client_id=b8b92e09fc804769ad39a8c8200612c5&response_type=code&redirect_uri=http://localhost:3000&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state%20playlist-modify-private%20playlist-modify-public"

export default function Login() {
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <Jumbotron>
        <h1 className="display-3">One Hit Wonder</h1>
        <p className="lead">Like a song a lot? Like... a LOT? Make a playlist of just that song!</p>
        <hr className="my-2" />
        <p>Login to get started!</p>
        <a className="btn btn-success btn-lg" href={AUTH_URL}>
          Login With Spotify
        </a>
      </Jumbotron>
    </Container>
  )
}
