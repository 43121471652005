import React from "react"
import { useState, useEffect } from "react"
import useAuth from "./useAuth"
import Player from "./Player"
import PlaylistMaker from "./PlaylistMaker"
import SelectedTrack from "./SelectedTrack"
import TrackSearchResult from "./TrackSearchResult"
import { Container, Form, Row, Col } from "react-bootstrap"
import SpotifyWebApi from "spotify-web-api-node"
require('dotenv').config()

const spotifyApi = new SpotifyWebApi({
  clientId: "b8b92e09fc804769ad39a8c8200612c5",
})

export default function Dashboard({ code }) {
  const accessToken = useAuth(code)
  const [search, setSearch] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [playingTrack, setPlayingTrack] = useState()
  const [lyrics, setLyrics] = useState("")
  const [playlistUrl, setPlaylistUrl] = useState('')

  function chooseTrack(track) {
    setPlayingTrack(track)
    setSearch("")
    setLyrics("")
  }

  useEffect(() => {
    if (!playingTrack) return
  }, [playingTrack])

  useEffect(() => {
    if (!accessToken) return
    spotifyApi.setAccessToken(accessToken)
  }, [accessToken])

  useEffect(() => {
    if (!search) return setSearchResults([])
    if (!accessToken) {

      const CLIENT_ID = process.env.CLIENT_ID;
      const CLIENT_SECRET = process.env.CLIENT_SECRET;
      const REDIRECT_URI = 'https://bcon-cloud.uc.r.appspot.com'; // redirect uri for prod
      //const REDIRECT_URI = 'http://localhost:3000'; // redirect uri for dev


      //console.log("no access token for search results :(");
      //console.log("code: " + code);
      //console.log("access token: " + accessToken);
      const spotifyApi = new SpotifyWebApi({
        redirectUri: REDIRECT_URI,
        clientId: CLIENT_ID,
        clientSecret: CLIENT_SECRET,
      })

      spotifyApi
        .authorizationCodeGrant(code)
        .then(data => {
            accessToken = data.body.access_token;
          })
        .catch(() => {
          console.log('error getting access token')
        })
      //console.log('accessToken after login call: ' + accessToken)
      //return;
    }

    let cancel = false
    spotifyApi.searchTracks(search).then(res => {
      if (cancel) return
      //console.log(res.body.tracks.items);
      setSearchResults(
        res.body.tracks.items.map(track => {
          const smallestAlbumImage = track.album.images.reduce(
            (smallest, image) => {
              if (image.height < smallest.height) return image
              return smallest
            },
            track.album.images[0]
          )

          return {
            artist: track.artists[0].name,
            title: track.name,
            uri: track.uri,
            albumUrl: smallestAlbumImage.url,
          }
        })
      )
    })

    return () => (cancel = true)
  }, [search, accessToken])

  function setPlaylistStatus(res) {
    if (res?.status == 200) {
      //success, display it somewhere
      setPlaylistUrl(res?.data?.external_urls?.spotify)
    }
    else {
      //something went wrong, display that
      setPlaylistUrl('something went wrong :( Please try again');
    }
  }

  return (
    <Container className="d-flex flex-column py-2" style={{ height: "100vh" }}>
      <h3>Like a song a lot? Like... a LOT? Make a playlist of <em>just</em> that song! </h3>
      <Row>
        <Col sm={10}>
          <Form.Control
            type="search"
            placeholder="Search Songs/Artists"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              setPlaylistUrl('');
            }} />
        </Col>
        <Col>
          <PlaylistMaker accessToken={accessToken} trackUri={playingTrack?.uri} code={code} setPlaylistStatus={setPlaylistStatus} />
        </Col>
      </Row>
      <div className="flex-grow-1 my-2" style={{ overflowY: "auto" }}>
        {(search) ? searchResults.map(track => (
          <TrackSearchResult
            track={track}
            key={track.uri}
            uri={track.uri}
            chooseTrack={chooseTrack}
          />
        )) : <SelectedTrack track={playingTrack} />}
        {playlistUrl ? <h3>Playlist Url: <a href={playlistUrl} target="_blank">{playlistUrl}</a></h3> : <></>}
      </div>
      <div>
        <Player accessToken={accessToken} trackUri={playingTrack?.uri} />
      </div>
    </Container>
  )
}
